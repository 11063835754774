import { defineComponent } from 'vue';
import QuartlyHumanResources from '@/views/QuartlyHumanResource/QuartlyHumanResources.vue';
export default defineComponent({
    name: 'QuartlyHumanResourceLayout',
    components: { QuartlyHumanResources },
    data() {
        return {
            loading: false,
            tab: 'Quartly Human Resources'
        };
    }
});
