import { render } from "./QuartlyHumanResourceDialog.vue?vue&type=template&id=c0b30a24&scoped=true"
import script from "./QuartlyHumanResourceDialog.vue?vue&type=script&lang=ts"
export * from "./QuartlyHumanResourceDialog.vue?vue&type=script&lang=ts"

import "./QuartlyHumanResourceDialog.vue?vue&type=style&index=0&id=c0b30a24&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-c0b30a24"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog});
