import { defineComponent } from 'vue';
import { deleteQuarterlyHrRecord, getQuarterlyHrRecords } from '@/api/dataentry-api-generated';
import QuartlyHumanResourceDialog from '@/components/QuartlyHumanResource/QuartlyHumanResourceDialog.vue';
import QuartlyHumanResourceDuplicateDialog from '@/components/QuartlyHumanResource/QuartlyHumanResourceDuplicateDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
export default defineComponent({
    name: 'QuartlyHumanResources',
    components: { QuartlyHumanResourceDialog, DeleteDialog, QuartlyHumanResourceDuplicateDialog },
    data() {
        return {
            loading: false,
            items: [],
            filter: '',
            selected: [],
            columns: [
                {
                    name: 'action',
                    label: 'EDIT',
                    align: 'center'
                },
                {
                    name: 'duplicate',
                    label: 'Duplicate',
                    align: 'center'
                },
                {
                    field: 'qrtHrProcessorLabel',
                    name: 'processor',
                    label: 'Processor',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'yearEntry',
                    name: 'yearEntry',
                    label: 'Year',
                    sortable: true,
                    align: 'center',
                    format: (val) => val || '-'
                },
                {
                    field: 'quarterEntry',
                    name: 'quarterEntry',
                    label: 'Quarter',
                    sortable: true,
                    align: 'center',
                    format: (val) => val || '-'
                },
                {
                    field: 'totalNumberOfStaff',
                    name: 'totalNumberOfStaff',
                    label: 'Total Number of Staff',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'averageDailyManning',
                    name: 'averageDailyManning',
                    label: 'Average Daily Manning',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOfCompanyEmployedStaff',
                    name: 'totalNumberOfCompanyEmployedStaff',
                    label: 'Total Number of Company Employed Staff',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOfLabourHireEmployees',
                    name: 'totalNumberOfLabourHireEmployees',
                    label: 'Total Number of Labour Hire Employees',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOf457VisaHolders',
                    name: 'totalNumberOf457VisaHolders',
                    label: 'Total Number of 457 Visa Holders',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOf482VisaHolders',
                    name: 'totalNumberOf482VisaHolders',
                    label: 'Total Number of 482 Visa Holders',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOf403VisaHolders',
                    name: 'totalNumberOf403VisaHolders',
                    label: 'Total Number of 403 Visa Holders',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOf417VisaHolders',
                    name: 'totalNumberOf417VisaHolders',
                    label: 'Total Number of 417 Visa Holders',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOfOtherVisaHolders',
                    name: 'totalNumberOfOtherVisaHolders',
                    label: 'Total Number Other Visa Holders',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOfMales',
                    name: 'totalNumberOfMales',
                    label: 'Total Number of Males',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOfFemales',
                    name: 'totalNumberOfFemales',
                    label: 'Total Number of Females',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOfNonIdentified',
                    name: 'totalNumberOfNonIdentified',
                    label: 'Total Number Non-Identified',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOfUnspecified',
                    name: 'totalNumberOfUnspecified',
                    label: 'Total Number Unspecified',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalNumberOfIdentifiedIndigenousStaff',
                    name: 'totalNumberOfIdentifiedIndigenousStaff',
                    label: 'Total Number of Identified Indigenous Staff',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'numberOfVacantPositions',
                    name: 'numberOfVacantPositions',
                    label: 'Number of Vacant Positions (Currently)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'unscheduledAbsenteeismRate',
                    name: 'unscheduledAbsenteeismRate',
                    label: 'Unscheduled Absenteeism Rate',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalTurnover',
                    name: 'totalTurnover',
                    label: 'Total Turnover',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'numberOfCompanyMeatInspectorsEmployed',
                    name: 'numberOfCompanyMeatInspectorsEmployed',
                    label: 'Number of Company Meat Inspectors Employed',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'numberOfExternalMeatInspectorsEmployed',
                    name: 'numberOfExternalMeatInspectorsEmployed',
                    label: 'Number of External Meat Inspectors Employed',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'thirdPartyCompanyUsed',
                    name: 'thirdPartyCompanyUsed',
                    label: 'Third Party Company Used',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                }
            ],
            tablePagination: {
                rowsPerPage: 50,
                rowsNumber: 0,
                sortBy: 'processor',
                descending: true,
                page: 1
            }
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        deleteDialogTitle() {
            const length = this.selected.length;
            const item = length > 1 ? 'Quarterly Human Resources' : 'Quarterly Human Resource';
            return `Delete ${length} ${item}`;
        },
        deleteDialogMsg() {
            const length = this.selected.length;
            const record = length > 1 ? 'these records' : 'this record';
            return `Are you sure you want to delete ${record}?`;
        }
    },
    methods: {
        refresh() {
            this.fetch({
                pagination: {
                    page: 1,
                    rowsNumber: this.tablePagination.rowsNumber,
                    sortBy: this.tablePagination.sortBy,
                    descending: this.tablePagination.descending,
                    rowsPerPage: this.tablePagination.rowsPerPage
                }
            });
        },
        async getItems(filter, pageNumber, pageSize, orderByField, orderByAscending) {
            this.loading = true;
            try {
                const result = await getQuarterlyHrRecords({ pageNumber: pageNumber, pageSize: pageSize, orderByField: orderByField, orderByAscending: orderByAscending, deleted: false });
                this.items = this.filterResults(result.items, filter);
                const newPagination = {
                    pageNumber: result.pageNumber,
                    totalNumberOfRecords: result.totalNumberOfRecords
                };
                this.setPagination(newPagination);
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        edit(uuid) {
            this.$router.push({ name: 'Quartile Human Resource', params: { uuid: uuid } });
        },
        async deleteItems() {
            await Promise.all(this.selected.map(async (i) => {
                try {
                    await deleteQuarterlyHrRecord(i.uuid);
                    this.$log.addMessage('Successfully deleted Quarterly Human Resource');
                }
                catch (error) {
                    this.$log.addError(error);
                }
            }));
            this.selected = [];
            this.refresh();
        },
        fetch(props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination;
            // update local pagination object
            this.tablePagination.page = page;
            this.tablePagination.rowsPerPage = rowsPerPage;
            this.tablePagination.sortBy = sortBy;
            this.tablePagination.descending = descending;
            // if sortBy is null, set to undefined - user has cleared the sort column
            sortBy = sortBy === null ? undefined : sortBy;
            this.getItems(this.filter, page, rowsPerPage, sortBy, !descending);
        },
        setPagination(newPagination) {
            this.tablePagination.page = newPagination.pageNumber;
            this.tablePagination.rowsNumber = newPagination.totalNumberOfRecords;
        },
        filterResults(results, filter) {
            if (filter !== undefined && filter.length > 0) {
                filter = filter ? filter.trim().toLowerCase() : filter;
                const filteredResults = results.filter((x) => {
                    let found = false;
                    // Search each individual 'value' in the object for the specified 'filter'
                    Object.keys(x).forEach(key => {
                        if (typeof x[key] === 'string') {
                            if (x[key].toLowerCase().includes(filter)) {
                                found = true;
                                // found something, stop searching
                            }
                        }
                        else if (typeof x[key] === 'number') {
                            if (x[key].toString().includes(filter)) {
                                found = true;
                                // found something
                            }
                        }
                    });
                    return found;
                });
                return filteredResults;
            }
            else {
                return results;
            }
        }
    }
});
